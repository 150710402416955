<template>
  <main-layout />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import router from '@router'

export default defineComponent({
  mounted: () => {
    router.replace({ path: '/' })
  },
})
</script>
